const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const SAVE_DATE_KEY = "save-date";
const DURATION_KEY = "duration";
function setItem(key, value, duration /* hours */) {
    window.sessionStorage.setItem(key, value);
    if (duration) {
        window.sessionStorage.setItem(SAVE_DATE_KEY, Date.now().toString());
        window.sessionStorage.setItem(DURATION_KEY, duration.toString());
    }
}
function getItem(key) {
    let value = window.sessionStorage.getItem(key);
    const saveDate = window.sessionStorage.getItem(SAVE_DATE_KEY);
    const duration = window.sessionStorage.getItem(DURATION_KEY);
    if (saveDate && duration) {
        const diffInHours = (Date.now() - Number(saveDate)) /
            (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR);
        if (Number(duration) <= diffInHours) {
            window.sessionStorage.removeItem(key);
            window.sessionStorage.removeItem(SAVE_DATE_KEY);
            window.sessionStorage.removeItem(DURATION_KEY);
            value = null;
        }
    }
    return value;
}
export default {
    setItem,
    getItem
};
