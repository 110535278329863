export interface AdDetails {
    [key: string]: string;
}

export interface AdConfig {
    [key: string]: AdDetails;
}

export interface PersistedState {
    cartAmount?: number;
    expirationDate?: number;
    isPopoverTouched?: boolean;
    adConfig?: AdConfig;
}

export const initialState: PersistedState = {
    cartAmount: 0,
    expirationDate: 0,
    isPopoverTouched: false,
    adConfig: undefined
};

export const getPersistedState = (storageKey: string): PersistedState => {
    try {
        return (
            JSON.parse(localStorage.getItem(storageKey) as string) ||
            initialState
        );
    } catch (e) {
        console.log("USC: Cannot get persisted state", e);
        return initialState;
    }
};

export const savePersistedState = (
    storageKey: string,
    state: PersistedState
): void => {
    try {
        localStorage.setItem(storageKey, JSON.stringify(state));
    } catch (e) {
        console.error(e);
    }
};
