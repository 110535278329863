import { nanoid } from "nanoid";
import isNil from "lodash-es/isNil";
import isUndefined from "lodash-es/isUndefined";
import { getObjectFromEntries } from "./general";
import { ANALYTICS_MESSAGES } from "../constants";
import SessionStorage from "./sessionStorage";
import { getDefaultContentData } from ".";
const UTM_PARAMS = [
    "utm_campaign",
    "utm_content",
    "utm_medium",
    "utm_source",
    "utm_term"
];
const UTM_PARAMS_UC = [
    "utm_campaign_uc",
    "utm_content_uc",
    "utm_medium_uc",
    "utm_program_uc",
    "utm_source_uc",
    "utm_term_uc"
];
const SHOPPABLE_MOMENT_ID = "shoppableMomentId";
const SESSION_ID_KEY = "session-id";
const SESSION_REFERRING_PAGE_KEY = "usc-referrer-page";
const HOURS_PER_DAY = 24;
const DURATION_SESSION_ID_DAYS = 30;
var OptimizelyEvents;
(function (OptimizelyEvents) {
    OptimizelyEvents["REVENUE"] = "trackRevenue";
    OptimizelyEvents["ORDERS"] = "orders";
    OptimizelyEvents["ITEMS"] = "items";
})(OptimizelyEvents || (OptimizelyEvents = {}));
function getUtmParams(isUC) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const parameters = isUC ? UTM_PARAMS_UC : [...UTM_PARAMS, ...UTM_PARAMS_UC];
    return parameters.reduce((accumulated, param) => (Object.assign(Object.assign({}, accumulated), (urlSearchParams.has(param) && {
        [param]: urlSearchParams.get(param)
    }))), {});
}
function getShoppableMomentId() {
    var _a;
    const url = new URL(window.location.href);
    const shoppableMomentId = decodeURIComponent((_a = url.searchParams.get(SHOPPABLE_MOMENT_ID)) !== null && _a !== void 0 ? _a : "");
    if (shoppableMomentId) {
        return { shoppableMomentId };
    }
    return {};
}
function getPageSlug(str) {
    var _a;
    const arr = str.split("/").filter(e => e);
    return (_a = arr[arr.length - 1]) !== null && _a !== void 0 ? _a : "";
}
function getCustomAttributes(analyticsData) {
    return Object.entries(analyticsData)
        .map(([key, value]) => `${key}:${value || ""}`)
        .join(";");
}
function parseCustomAttributes(attributes) {
    return getObjectFromEntries(attributes.split(";").map(pair => pair.split(":")));
}
function getProductInfoAttributes(product) {
    return {
        productId: `${product === null || product === void 0 ? void 0 : product.channelKey}:${product.productKey}`,
        productName: (product === null || product === void 0 ? void 0 : product.name) || null,
        sellerId: (product === null || product === void 0 ? void 0 : product.channelKey) || null,
        sellerName: (product === null || product === void 0 ? void 0 : product.seller) || null,
        sponsorName: (product === null || product === void 0 ? void 0 : product.brandName) || null
    };
}
function getSponsorEventAttributes(parsedAnalyticsDataAttributes, productInfoAttributes) {
    const pageSlug = getPageSlug(window.location.pathname);
    const pageName = document.title;
    const domain = window.location.host;
    const url = window.location.toString();
    const { contentName, contentType } = getDefaultContentData();
    return Object.assign(Object.assign(Object.assign(Object.assign({ contentName,
        contentType,
        pageSlug,
        pageName,
        domain,
        url }, productInfoAttributes), parsedAnalyticsDataAttributes), getShoppableMomentId()), getUtmParams(true));
}
function addOptimizelyEvent(eventName, value) {
    window.optimizely = window.optimizely || [];
    const tags = eventName === OptimizelyEvents.REVENUE
        ? {
            revenue: value
        }
        : { value };
    window.optimizely.push({
        type: "event",
        eventName,
        tags
    });
}
function logError(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
error, attrs, errorOrigin) {
    console.error("USC:", error);
    if (!window.mParticle) {
        console.error("USC: [mParticle] analyticsLogError not sent.");
        return;
    }
    const errorWithOrigin = errorOrigin
        ? Object.assign(Object.assign({}, error), { message: `${errorOrigin}: ${error.message}` }) : error;
    try {
        // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle%20&%20mParticleInstance.html#method_logError
        window.mParticle.logError(errorWithOrigin, Object.assign({ sourceUrl: window.location.href }, attrs));
    }
    catch (e) {
        console.error("USC: [mParticle] analyticsLogError error sending event.", e);
    }
}
/**
 * Checks if hostnames are the same for provided urls
 */
function areTheSameHostnames(strUrlA, strUrlB) {
    let urlA;
    let urlB;
    if (strUrlA === "" || strUrlB === "") {
        return undefined;
    }
    try {
        urlA = new URL(strUrlA);
        urlB = new URL(strUrlB);
    }
    catch (error) {
        logError(error);
        return undefined;
    }
    if (urlA !== undefined && urlB !== undefined) {
        return urlA.hostname === urlB.hostname;
    }
    return undefined;
}
function enabled() {
    return !!window.mParticle;
}
function setReferringPage(referringPage) {
    var _a;
    const currentReferringPage = SessionStorage.getItem(SESSION_REFERRING_PAGE_KEY);
    if (isNil(currentReferringPage)) {
        const currentUrl = (_a = window === null || window === void 0 ? void 0 : window.location.href) !== null && _a !== void 0 ? _a : "";
        const areSame = areTheSameHostnames(currentUrl, referringPage);
        // If hosts are the same or an error occured checking hosts, we save empty string
        if (isUndefined(areSame) || areSame) {
            SessionStorage.setItem(SESSION_REFERRING_PAGE_KEY, "");
        }
        else {
            SessionStorage.setItem(SESSION_REFERRING_PAGE_KEY, referringPage);
        }
    }
}
function getCommonAttributes() {
    const referringPage = SessionStorage.getItem(SESSION_REFERRING_PAGE_KEY) || "";
    return {
        domain: window.location.host,
        referringPage
    };
}
function logCustomEvent(name, type, attributes) {
    if (!enabled()) {
        console.log(ANALYTICS_MESSAGES.analyticsLogCustomEvent.info);
        return;
    }
    const customFlags = {
        "Facebook.EventSourceUrl": window.location.href
    };
    const data = Object.assign(Object.assign({}, getCommonAttributes()), attributes);
    try {
        // https://docs.mparticle.com/developers/sdk/web/core-apidocs/classes/mParticle%20&%20mParticleInstance.html#method_logEvent
        window.mParticle.logEvent(name, type, data, customFlags);
    }
    catch (e) {
        console.error(ANALYTICS_MESSAGES.analyticsLogCustomEvent.error, e);
    }
}
function getMiraklProductCategoriesAttributes(miraklCategories) {
    var _a;
    const categoriesKeys = ((_a = miraklCategories === null || miraklCategories === void 0 ? void 0 : miraklCategories.find(category => category.miraklCategoriesKeys)) === null || _a === void 0 ? void 0 : _a.miraklCategoriesKeys) || [];
    return categoriesKeys.reduce((prevCategoryKey, categoryKey, index) => (Object.assign(Object.assign({}, prevCategoryKey), { [`L${index + 1}_category`]: categoryKey })), {});
}
function getSessionId() {
    var _a, _b, _c;
    let sessionId = SessionStorage.getItem(SESSION_ID_KEY);
    const mParticleSessionId = ((_b = (_a = window.mParticle) === null || _a === void 0 ? void 0 : _a.Identity) === null || _b === void 0 ? void 0 : _b.getCurrentUser)
        ? (_c = window.mParticle.Identity.getCurrentUser()) === null || _c === void 0 ? void 0 : _c.getMPID()
        : undefined;
    if (!sessionId && !mParticleSessionId) {
        sessionId = nanoid();
        SessionStorage.setItem(SESSION_ID_KEY, sessionId, HOURS_PER_DAY * DURATION_SESSION_ID_DAYS);
    }
    return sessionId || mParticleSessionId;
}
export default {
    OptimizelyEvents,
    addOptimizelyEvent,
    enabled,
    getUtmParams,
    getPageSlug,
    getMiraklProductCategoriesAttributes,
    getCustomAttributes,
    getProductInfoAttributes,
    getSponsorEventAttributes,
    getSessionId,
    getShoppableMomentId,
    logError,
    logCustomEvent,
    parseCustomAttributes,
    getCommonAttributes,
    setReferringPage,
    areTheSameHostnames
};
