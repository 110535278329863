import { ShoppableProduct, UscProduct, ShoppableKeyDataItem } from "../types";
import { PDP_LOCATION_HASH_PREFIX, parseQuery } from "../utils/url";
import { checkIsAnchorTagWithPdpPrefix } from "../utils/dom";

declare const BASE_API_URL: string;

export class ProductsService {
    private products: Array<ShoppableProduct> = [];

    public fetchProducts = async (
        elements: NodeListOf<HTMLElement>,
        networkBrand: string
    ): Promise<void> => {
        if (!networkBrand || !window?.mParticle) {
            return;
        }

        let productsData: ShoppableProduct[];

        try {
            let shoppableKeyData: ShoppableKeyDataItem[] = [];

            elements.forEach((element: HTMLElement) => {
                if (checkIsAnchorTagWithPdpPrefix(element)) {
                    const shoppableKeyDataItem =
                        this.getShoppableKeyDataFromAnchor(element);

                    if (shoppableKeyDataItem) {
                        shoppableKeyData.push(shoppableKeyDataItem);
                        return;
                    }
                }

                if (
                    element.dataset.productKey &&
                    element.dataset.productChannelKey &&
                    !this.isDuplicateProduct(
                        element.dataset.productKey,
                        element.dataset.productChannelKey
                    )
                ) {
                    shoppableKeyData.push({
                        key: element.dataset.productKey,
                        priceChannelKey: element.dataset.productChannelKey
                    });
                }
            });

            shoppableKeyData = [...new Set(shoppableKeyData)];

            if (!shoppableKeyData.length) {
                return;
            }

            const response = await fetch(
                `${BASE_API_URL}/v3/${networkBrand}/products?skipProductsWithEmptyPrices=false`,
                {
                    method: "POST",
                    body: JSON.stringify(shoppableKeyData)
                }
            );

            const uscProducts = await response.json();

            productsData = this.formatProductData(uscProducts);
        } catch (error) {
            console.error("USC: fetch products failed.", error);
            productsData = [];
        }

        this.products = [...this.products, ...productsData];
    };

    public resetProducts = (): void => {
        this.products = [];
    };

    public getProductData = (productKey: string) =>
        this.products.find(product => productKey === product?.productKey);

    private isDuplicateProduct = (productKey: string, channelKey: string) =>
        this.products.find(
            product =>
                product.productKey === productKey &&
                product.channelKey === channelKey
        );

    private getShoppableKeyDataFromAnchor = (
        element: HTMLLinkElement
    ): ShoppableKeyDataItem | null => {
        const queryString =
            element.href.split(`${PDP_LOCATION_HASH_PREFIX}?`)[1] ?? "";

        const params = parseQuery<{ [key: string]: string }>(queryString);

        const productKey = params["product-key"];
        const productChannelKey = params["channel-key"];

        if (
            productKey &&
            productChannelKey &&
            !this.isDuplicateProduct(productKey, productChannelKey)
        ) {
            return {
                key: productKey,
                priceChannelKey: productChannelKey
            };
        }

        return null;
    };

    // eslint-disable-next-line class-methods-use-this
    private formatProductData = (
        products: Array<UscProduct>
    ): Array<ShoppableProduct> => {
        if (!Array.isArray(products) || !products.length) return [];

        return products.map(product => {
            const masterProduct = product.variants?.[0];

            return {
                productId: product.id,
                productKey: product.key,
                brand: masterProduct?.sameAttributes?.brand || "",
                sellerName: masterProduct?.offers?.[0].channel?.name,
                channelKey: masterProduct?.offers?.[0].channel?.key,
                name: product.name,
                categories: product?.categories,
                isBackordered: masterProduct?.isBackordered,
                isDelayedShipping: masterProduct?.isDelayedShipping
            };
        });
    };
}
