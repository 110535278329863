import { Analytics, getContextualRecommendationParams } from "common/lib/utils";
import { RecommendParams } from "common/lib/interfaces/configs";
import { RecommendProduct } from "common/lib/interfaces/product";

declare const ENV: string;

const SANDBOX_MODE = ENV !== "public";

export const getRecommendations = async (
    { tags, limit }: { tags?: RecommendParams["tags"]; limit: number },
    personifyApiUrl: string
): Promise<RecommendProduct[]> => {
    const recommendationParams = getContextualRecommendationParams();
    const tagsFilter = tags?.map(tag => ({ item_id: tag })) ?? [];
    const pageSize = limit * 2;

    const recommendationBody = {
        ...recommendationParams,
        sessionid: Analytics.getSessionId(),
        pageSize,
        overrides: [
            {
                rule: "ALL",
                type: "limitCategories",
                items: tagsFilter
            }
        ],
        forcedetails: true,
        test: SANDBOX_MODE,
        includescores: true
    };

    try {
        const request = await fetch(`${personifyApiUrl}/getrecs`, {
            method: "POST",
            body: JSON.stringify(recommendationBody)
        });

        const response = await request.json();

        // Sometimes we observe strange behavior when our backend receives
        // a lot of duplicated recommended products. To investigate it
        // we want to check if Personify sends us mistakenly duplications in
        // their response
        // TODO: remove after investigation
        const stringifiedResponse = response?.recommendations
            ?.map(({ productcode }: RecommendProduct) => productcode)
            ?.join(",");
        Analytics.logError(`INFO: Personify Response: ${stringifiedResponse}`);

        return response?.recommendations || [];
    } catch (error) {
        console.error(error);

        return [];
    }
};
