// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Fragment, h } from "preact";
import ResizeObserver from "resize-observer-polyfill";

import { WireWaxEvent, WidgetConfig } from "common/lib/interfaces";
import { RenewalTimeUnit } from "common/lib/constants";
import {
    clearResponsiveAdsProductKeysStorage,
    writeContentDataByTypeToStorage,
    writeResponsiveAdsProductKeyToStorage
} from "common/lib/utils";
import {
    trackCitrusClick,
    trackCitrusImpression
} from "common/lib/service/citrus";
import ShoppingCartButton from "../components/shopping-cart";
import ShoppingOutlet from "../components/shopping-outlet";
import StageListener from "../components/stage-listener/StageListener";
import { IProduct, TOpenWidget } from "./config";
import {
    AMAZON_CHECKOUT_HASH_PREFIX,
    AMAZON_CHECKOUT_REGEXP,
    AMAZON_CHECKOUT_SESSION_ID_QUERY_PARAM,
    CART_LOCATION_HASH_PREFIX,
    RefProductMetadata,
    parseQuery,
    isMessageExistKeys,
    ORDER_LOOKUP_LOCATION_HASH,
    START_A_RETURN_LOCATION_HASH,
    ORDER_HISTORY_LOCATION_HASH_PREFIX,
    PDP_LOCATION_HASH_PREFIX,
    INCOMING_MESSAGE_ERRORS,
    IFrameMessageType,
    USC_PREFIX,
    SHOP_ALL_HASH_PREFIX
} from "../utils/url";
import {
    getShoppableProducts,
    hasRequiredElements,
    hasWirewaxVideo,
    checkIsAnchorTagWithPdpPrefix,
    CHANNEL_KEY_ATTRIBUTE,
    PRODUCT_KEY_ATTRIBUTE,
    PRODUCT_SKU_ATTRIBUTE,
    ProductPlacement,
    SUPPRESS_WIDGET_OPENING,
    PRODUCT_MERCHANDISE_ATTRIBUTES,
    ANALYTICS_CUSTOM_ATTRIBUTES
} from "../utils/dom";
import "../styles/main.scss";
import { getPersistedState, savePersistedState } from "./state";
import {
    analyticsOnProductClicked,
    analyticsOnShoppableContentLoad,
    logProductViewed,
    rescanUSCAnalytics
} from "../utils/analytics";

declare const ENV: string;

interface AppState {
    cartAmount: number;
    isShoppingCartHidden: boolean;
    isHideButton: boolean;
}

const analyticsEnabled = () => !!window.mParticle;

export default class App extends Component<WidgetConfig, AppState> {
    private storageKey: string;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    private shoppingOutlet: ShoppingOutlet;

    private replaceTargetValue?: boolean;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private resizeTimeout: any;

    private iframeProducts: ProductPlacement[] = [];

    // eslint-disable-next-line react/state-in-constructor
    state: AppState;

    private isWirewaxEventListenerAttached = false;

    constructor(props: WidgetConfig) {
        super(props);

        this.storageKey = `persist:usc-bootstrap-state-${ENV}-${props.networkBrand}`;
        const storedState = getPersistedState(this.storageKey);

        this.state = {
            cartAmount: storedState.cartAmount ?? 0,
            isShoppingCartHidden: true,
            isHideButton: false
        };

        this.replaceTargetValue = props.replaceTargetValue;
    }

    componentDidMount() {
        this.rescan();

        window.addEventListener("click", this.openProductDetailsPage, true);
        window.addEventListener("hashchange", this.onLocationHashChange, false);
        window.addEventListener("message", this.handleGetMessage, false);
        window.addEventListener("pageshow", this.handlePageShow);

        this.handleResizeWindow();
        this.onLocationHashChange();

        const watch = new ResizeObserver(() => this.handleResizeWindow());

        watch.observe(document.body);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentDidUpdate(_: any, prevState: any) {
        const { cartAmount } = this.state;
        if (prevState.cartAmount === cartAmount) return;

        const storedState = getPersistedState(this.storageKey);

        this.setState({ cartAmount: storedState.cartAmount });
        this.setIsShoppingCartHidden();
    }

    componentWillUnmount(): void {
        window.removeEventListener("click", this.openProductDetailsPage);
        window.removeEventListener("hashchange", this.onLocationHashChange);
        window.removeEventListener("message", this.handleGetMessage, false);
        window.removeEventListener("pageshow", this.handlePageShow);
    }

    private handlePageShow = (event: PageTransitionEvent) => {
        if (event.persisted) {
            const persistedState = getPersistedState(this.storageKey);

            this.setState(prevState => ({
                ...prevState,
                cartAmount: persistedState.cartAmount
            }));
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handleGetMessage = (event: any) => {
        if (!event.data.usc) return;

        switch (event.data.usc?.type) {
            case IFrameMessageType.PRODUCT_CLICKED:
                if (isMessageExistKeys(event.data)) {
                    const adDetails = event.data.usc.data?.adDetails
                        ? JSON.stringify(event.data.usc.data?.adDetails)
                        : null;

                    const productKey = event.data.usc.data.product.key;
                    const channelKey = event.data.usc.data.channel.key;
                    const productSku = event.data.usc.data.product.sku;
                    const { adId } = event.data.usc.data;
                    const queryProduct = productSku
                        ? `product-sku=${productSku}`
                        : `product-key=${productKey}`;

                    const newLocation = `${PDP_LOCATION_HASH_PREFIX}?${queryProduct}&channel-key=${channelKey}`;

                    const hash = adDetails
                        ? `${newLocation}&ad=${window.btoa(adDetails)}`
                        : newLocation;

                    window.history.pushState({}, "", hash);
                    window.dispatchEvent(new HashChangeEvent("hashchange"));
                    writeResponsiveAdsProductKeyToStorage(productKey);
                    analyticsOnProductClicked({
                        productKey,
                        channelKey,
                        citrusAdId: adId
                    });
                    if (adId) {
                        trackCitrusClick(this.props.networkBrand, adId);
                    }
                } else {
                    console.error(
                        `USC: ${INCOMING_MESSAGE_ERRORS.wrongFormat}`
                    );
                }
                break;
            case IFrameMessageType.PRODUCT_VIEWED:
                {
                    const { productKey, channelKey, adId } =
                        event.data.usc.data;
                    logProductViewed({
                        productKey,
                        channelKey,
                        citrusAdId: adId
                    });
                    if (adId) {
                        trackCitrusImpression(this.props.networkBrand, adId);
                    }
                }
                break;
            case IFrameMessageType.PRODUCTS_LIST:
                {
                    event.source.postMessage(
                        {
                            type: IFrameMessageType.PRODUCTS_LIST,
                            message: "Received"
                        },
                        event.origin
                    );

                    const products = event.data.usc.data?.map(
                        ({ productKey, sku, channelKey }: IProduct) => ({
                            productKey,
                            channelKey,
                            productSku: sku
                        })
                    );
                    this.iframeProducts = products.length > 0 ? products : [];
                    this.rescan();
                }

                break;
            case IFrameMessageType.SHOPPABLE_CONTENT_LOADED:
                clearResponsiveAdsProductKeysStorage();
                writeContentDataByTypeToStorage(
                    event.data.usc.data.content || {}
                );
                analyticsOnShoppableContentLoad(event.data.usc.data);
                break;
            case IFrameMessageType.PAGE_SEGMENT_NAME:
                writeContentDataByTypeToStorage(
                    event.data.usc.data.content || {}
                );
                break;
            case IFrameMessageType.CCW_SHOPPABLE_CONTENT_LOADED: {
                const { networkBrand } = this.props;
                writeContentDataByTypeToStorage(
                    event.data.usc.data.content || {}
                );
                analyticsOnShoppableContentLoad(event.data.usc.data);
                rescanUSCAnalytics(networkBrand);
                this.rescan();
                break;
            }
            case IFrameMessageType.EPW_SHOPPABLE_CONTENT_LOADED:
                analyticsOnShoppableContentLoad(event.data.usc.data);
                break;
            default:
                console.error(
                    `USC: Unknown message type: ${event.data.usc?.type}. Please use available types:`,
                    Object.values(IFrameMessageType).join(", ")
                );
        }
    };

    private handleResizeWindow() {
        if (this.resizeTimeout) return;
        this.resizeTimeout = setTimeout(() => {
            this.resizeTimeout = null;
            this.onChangeAllShopableLinks();
        }, 1000);
    }

    private setIsShoppingCartHidden() {
        const { cartAmount } = this.state;
        const { networkBrand } = this.props;
        this.setState({
            isShoppingCartHidden: !(
                hasRequiredElements(this.iframeProducts, networkBrand) ||
                cartAmount
            )
        });
    }

    private openProductDetailsPage = (event: Event) => {
        const elem = (event.target as HTMLElement).closest(
            `[data-${PRODUCT_KEY_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}], [data-${PRODUCT_SKU_ATTRIBUTE}][data-${CHANNEL_KEY_ATTRIBUTE}]`
        );

        const targetElement = event.target as HTMLElement;
        const isSuppressWidgetOpening =
            targetElement.attributes.getNamedItem(
                `data-${SUPPRESS_WIDGET_OPENING}`
            )?.value === "true" ||
            targetElement
                .closest(`[data-${SUPPRESS_WIDGET_OPENING}]`)
                ?.attributes.getNamedItem(`data-${SUPPRESS_WIDGET_OPENING}`)
                ?.value === "true";
        if (isSuppressWidgetOpening) {
            return;
        }

        if (elem) {
            if (checkIsAnchorTagWithPdpPrefix(elem)) {
                return;
            }
            const productKey =
                elem.attributes.getNamedItem(`data-${PRODUCT_KEY_ATTRIBUTE}`)
                    ?.value || "";
            const channelKey =
                elem.attributes.getNamedItem(`data-${CHANNEL_KEY_ATTRIBUTE}`)
                    ?.value || "";
            const productSku =
                elem.attributes.getNamedItem(`data-${PRODUCT_SKU_ATTRIBUTE}`)
                    ?.value || "";

            const merchandisedData =
                elem.attributes.getNamedItem(
                    `data-${PRODUCT_MERCHANDISE_ATTRIBUTES}`
                )?.value || "";
            const suppressWidgetOpening =
                elem.attributes.getNamedItem(`data-${SUPPRESS_WIDGET_OPENING}`)
                    ?.value || "";
            const customAttributes =
                elem.attributes.getNamedItem(
                    `data-${ANALYTICS_CUSTOM_ATTRIBUTES}`
                )?.value || "";

            if (suppressWidgetOpening === "true") {
                return;
            }

            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.ProductDetails, {
                    productKey,
                    channelKey,
                    productSku,
                    customAttributes,
                    merchandisedData
                });
            });
        }
    };

    private onChangeAllShopableLinks = () => {
        if (!this.replaceTargetValue) return;
        const shopableLinks = [
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(document.querySelectorAll(`[href*='${USC_PREFIX}']`) as any)
        ] as HTMLAnchorElement[];
        shopableLinks.forEach(link => {
            const href = link.href.split(USC_PREFIX)[1];
            // eslint-disable-next-line no-param-reassign
            link.href = `${USC_PREFIX}${href}`;
            if (link.target === "_blank") {
                // eslint-disable-next-line no-param-reassign
                link.target = "_self";
            }
        });
    };

    private onLocationHashChange = () => {
        window.usc?.setPageProducts(getShoppableProducts(this.iframeProducts));

        if (window.location.hash === ORDER_LOOKUP_LOCATION_HASH) {
            this.setState({
                isShoppingCartHidden: true,
                isHideButton: true
            });

            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.OrderLookup);
            });
        } else if (window.location.hash === START_A_RETURN_LOCATION_HASH) {
            this.setState({
                isShoppingCartHidden: true,
                isHideButton: true
            });

            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.StartReturn);
            });
        } else if (
            window.location.hash.includes(ORDER_HISTORY_LOCATION_HASH_PREFIX)
        ) {
            this.setState({
                isShoppingCartHidden: true,
                isHideButton: true
            });

            const magicLinkHash = window.location.hash.split("=")[1];
            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.OrderHistory, {
                    magicLinkHash
                });
            });
        } else if (window.location.hash.includes(PDP_LOCATION_HASH_PREFIX)) {
            // fix CC-7121 Meta URL Issue
            const decodedHash = decodeURIComponent(
                window.location.hash.replace(/\+/g, " ")
            );

            const queryString = decodedHash.split(
                `${PDP_LOCATION_HASH_PREFIX}?`
            )[1];
            const params = parseQuery<{ [key: string]: string }>(queryString);

            const productKey = params["product-key"];
            const channelKey = params["channel-key"];
            const productSku = params["product-sku"];
            const adDetails = params.ad ? window.atob(params.ad) : null;

            if ((!productSku && !productKey) || !channelKey) return;

            this.setState({ isShoppingCartHidden: true });
            this.shoppingOutlet.renderOutlet(() => {
                if (analyticsEnabled() && adDetails) {
                    const persistedState = getPersistedState(this.storageKey);

                    const adDetailsParsed = JSON.parse(adDetails);

                    if (persistedState.adConfig) {
                        persistedState.adConfig[productKey] = adDetailsParsed;
                    } else {
                        persistedState.adConfig = {
                            [productKey]: adDetailsParsed
                        };
                    }

                    savePersistedState(this.storageKey, persistedState);
                }
                window.usc.openTab(window.usc.tab.ProductDetails, {
                    productKey,
                    channelKey,
                    productSku
                });
            });
        } else if (window.location.hash.includes(CART_LOCATION_HASH_PREFIX)) {
            const { lang, networkBrand } = this.props;
            const queryString =
                window.location.search ||
                window.location.hash.split(`${CART_LOCATION_HASH_PREFIX}?`)[1];
            const params = parseQuery<{ [key: string]: string }>(queryString);
            const qs = params.cart;
            const { cartId } = params;

            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.Cart, {
                    qs,
                    cartId,
                    locale: lang,
                    networkBrand
                });
            });
        } else if (window.location.hash.includes(AMAZON_CHECKOUT_HASH_PREFIX)) {
            const { lang, networkBrand } = this.props;
            const queryString = window.location.hash.split(
                `${AMAZON_CHECKOUT_HASH_PREFIX}?`
            )[1];
            const params = parseQuery<{ [key: string]: string }>(queryString);
            const { qs } = params;
            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.ThankYou, {
                    qs,
                    locale: lang,
                    networkBrand
                });
            });
            window.history.replaceState(
                null,
                "",
                window.location.pathname +
                window.location.search
                    .replace(AMAZON_CHECKOUT_REGEXP, "")
                    .replace(/^&/, "?")
            );
        } else if (
            window.location.search.includes(
                AMAZON_CHECKOUT_SESSION_ID_QUERY_PARAM
            )
        ) {
            const queryString = window.location.search;
            const params = parseQuery<{ [key: string]: string }>(queryString);
            const amazonCheckoutSessionId =
                params[AMAZON_CHECKOUT_SESSION_ID_QUERY_PARAM];
            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.Checkout, {
                    amazonCheckoutSessionId
                });
            });
            window.history.replaceState(
                null,
                "",
                window.location.pathname +
                window.location.search
                    .replace(AMAZON_CHECKOUT_REGEXP, "")
                    .replace(/^&/, "?")
            );
        } else if (window.location.hash.includes(SHOP_ALL_HASH_PREFIX)) {
            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.ShopAll);
            });
        } else {
            this.setIsShoppingCartHidden();
            this.shoppingOutlet.hideUSCWidget();
        }
    };

    private handleWaxVidTagClick = (e: WireWaxEvent): void => {
        if (e.data.tagData.customNameRef) {
            const customRefParams = parseQuery<RefProductMetadata>(
                e.data.tagData.customNameRef
            );

            const productKey = customRefParams["product-key"];
            const channelKey = customRefParams["channel-key"];
            const productSku = customRefParams["product-sku"];

            if ((productKey || productSku) && channelKey) {
                this.shoppingOutlet.renderOutlet(() => {
                    window.usc.openTab(window.usc.tab.ProductDetails, {
                        productKey,
                        channelKey,
                        productSku
                    });
                });
            }
        }
    };

    public hideShoppingButton = (isHide: boolean) => {
        this.setState({
            isHideButton: isHide
        });
    };

    // eslint-disable-next-line react/no-unused-class-component-methods
    public openWidget: TOpenWidget = productParams => {
        this.setState({
            isShoppingCartHidden: false
        });

        const isProductParamsExist =
            productParams && Object.keys(productParams).length > 0;

        if (isProductParamsExist) {
            this.shoppingOutlet.renderOutlet(() => {
                window.usc.openTab(window.usc.tab.ProductDetails, {
                    ...productParams
                });
            });
        } else {
            console.log(
                'USC openWidget: please set valid param in ".open() method"'
            );
        }
    };

    public rescan() {
        this.setIsShoppingCartHidden();
        window.usc?.setPageProducts(getShoppableProducts(this.iframeProducts));

        if (window.wirewax) {
            this.attachWirewaxListener();
        } else if (hasWirewaxVideo()) {
            const script = document.createElement("script");
            script.src =
                "//ww4player.s3.amazonaws.com/ww4release/javascripts/wirewax-iframe-api.js";
            script.onload = () => {
                this.attachWirewaxListener();
            };
            document.body.appendChild(script);
        }
    }

    private openShoppingCart() {
        const { cartAmount } = this.state;
        this.hideShoppingButton(true);
        this.shoppingOutlet.renderOutlet(() => {
            if (cartAmount) {
                window.usc.openTab(window.usc.tab.Cart);
            } else {
                window.usc.openTab(window.usc.tab.ShopAll);
            }
        });
    }

    private attachWirewaxListener() {
        // wirewax doesn't have removeEventListener (https://wirewax.github.io/docs/#/)
        if (!this.isWirewaxEventListenerAttached) {
            window.wirewax.addEventListener(
                window.wirewax.events.listeners.TAG_CLICK,
                this.handleWaxVidTagClick
            );
            this.isWirewaxEventListenerAttached = true;
        }
    }

    render() {
        const {
            lang,
            theme,
            networkBrand,
            tooltipEnabled,
            popoverRenewalTimeAmount,
            popoverRenewalTimeUnit,
            placement,
            uscRevamp
        } = this.props;

        const { cartAmount, isShoppingCartHidden, isHideButton } = this.state;

        return (
            <Fragment>
                <ShoppingOutlet
                    ref={el => {
                        this.shoppingOutlet = el;
                    }}
                    lang={lang}
                    theme={theme}
                    networkBrand={networkBrand}
                    iframeProducts={this.iframeProducts}
                    hideShoppingButton={this.hideShoppingButton}
                    onCartAmountChange={amount => {
                        const perstistedState = getPersistedState(
                            this.storageKey
                        );

                        perstistedState.cartAmount = amount;

                        savePersistedState(this.storageKey, perstistedState);
                        this.setState({ cartAmount: amount });
                    }}
                />
                <ShoppingCartButton
                    lang={lang}
                    tooltipEnabled={tooltipEnabled}
                    cartItemsCount={cartAmount}
                    isHidden={isShoppingCartHidden}
                    isHideButton={isHideButton}
                    storageKey={this.storageKey}
                    popoverRenewalTimeUnit={
                        popoverRenewalTimeUnit || RenewalTimeUnit.Days
                    }
                    popoverRenewalTimeAmount={popoverRenewalTimeAmount || 1}
                    openShoppingCartFn={() => this.openShoppingCart()}
                    placement={placement}
                />
                {uscRevamp && <StageListener />}
            </Fragment>
        );
    }
}
