export var ContentType;
(function (ContentType) {
    ContentType["EPW"] = "Embeddable Products Widget";
    ContentType["CCW"] = "Contextual Commerce Widget";
    ContentType["SPONSORSHIP_BANNER"] = "sponsorship banner";
    ContentType["SPONSORED_PRODUCTS"] = "Sponsored Products";
})(ContentType || (ContentType = {}));
export var CustomEventType;
(function (CustomEventType) {
    CustomEventType[CustomEventType["Navigation"] = 1] = "Navigation";
    CustomEventType[CustomEventType["Location"] = 2] = "Location";
    CustomEventType[CustomEventType["Search"] = 3] = "Search";
    CustomEventType[CustomEventType["Transaction"] = 4] = "Transaction";
    CustomEventType[CustomEventType["UserContent"] = 5] = "UserContent";
    CustomEventType[CustomEventType["UserPreference"] = 6] = "UserPreference";
    CustomEventType[CustomEventType["Social"] = 7] = "Social";
    CustomEventType[CustomEventType["Other"] = 8] = "Other";
})(CustomEventType || (CustomEventType = {}));
export const ANALYTICS_MESSAGES = {
    analyticsLogCustomEvent: {
        info: "USC: [mParticle] analyticsLogCustomEvent not sent.",
        error: "USC: [mParticle] analyticsLogCustomEvent error sending event."
    },
    analyticsLogImpression: {
        info: "USC: [mParticle] analyticsLogImpression not sent.",
        error: "USC: [mParticle] analyticsLogImpression error sending event."
    },
    analyticsLogProductAction: {
        info: "USC: [mParticle] analyticsLogProductAction not sent.",
        error: "USC: [mParticle] analyticsLogProductAction error sending event."
    },
    analyticsLogLineItemAction: {
        info: "USC: [mParticle] analyticsLogLineItemAction not sent.",
        error: "USC: [mParticle] analyticsLogLineItemAction error sending event."
    },
    analyticsLogCheckoutOption: {
        info: "USC: [mParticle] analyticsLogCheckoutOption not sent.",
        error: "USC: [mParticle] analyticsLogCheckoutOption error sending event."
    },
    analyticsLogPurchase: {
        info: "USC: [mParticle] analyticsLogPurchase not sent.",
        error: "USC: [mParticle] analyticsLogPurchase error sending event."
    },
    analyticsModifyIdentity: {
        info: "USC: [mParticle] analyticsModifyIdentity not sent.",
        error: "USC: [mParticle] analyticsModifyIdentity error sending event."
    }
};
